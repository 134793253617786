import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
import { motion, Variants } from "framer-motion";
import { IconButton } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const HomePageIta = () => {
	const navigate = useNavigate();

	const [pointer, setPointer] = useState({ x: 0, y: 0 });

	const [menu, toggleMenu] = useState(false);

	const TabHeader: Array<string> = ["Home", "Su di me", "Progetti", "Appunti", "Contatti"];
	const [cursorVariant, setCursorVariant] = useState("default");

	const [currentInfo, setCurrentInfo] = useState<string>(TabHeader[0]);
	const [busy, pageBusy] = useState(false);

	const variants: Variants = {
		default: {},
		click: {
			height: 70,
			width: 70,
			backgroundColor: "rgb(255,255,255)",
			backgroundSize: "50%",
			mixBlendMode: "lighten",
		},
		link: {
			height: 0,
			width: 0,
		},
	};

	const cardEnter = () => setCursorVariant("click");
	const linkEnter = () => setCursorVariant("link");
	const setDeafult = () => setCursorVariant("default");

	useEffect(() => {
		const mouse_animation = (e: MouseEvent) => {
			setPointer((prevState) => ({ ...prevState, x: e.clientX, y: e.clientY }));
		};

		window.addEventListener("mousemove", mouse_animation);

		return () => {
			window.removeEventListener("mousemove", mouse_animation);
		};
	}, []);

	const project = (title: string, description: string, onClick: MouseEventHandler) => {
		return (
			<Card sx={{ maxWidth: "250px" }} style={{ backgroundColor: "#2e2e2e", margin: "20px" }}>
				<CardMedia sx={{ height: 140 }} image={`/media/images/${title.toLowerCase().replace(" ", "-")}.jpg`} title={title} />
				<CardContent>
					<Typography lang="en" style={{ fontFamily: "Anton", textTransform: "uppercase", color: "white" }} gutterBottom variant="h5" component="div">
						{title}
					</Typography>
					<Typography lang="en" style={{ fontFamily: "Cutive Mono", color: "white" }} variant="body2">
						{description}
					</Typography>
				</CardContent>
				<CardActions>
					<Button onClick={onClick} style={{ fontFamily: "Anton", color: "#cfb53b", backgroundColor: "#3e3e3e" }} size="large" color="secondary" variant="contained">
						Visit
					</Button>
				</CardActions>
			</Card>
		);
	};

	const clickInfo = (name: string) => {
		if (busy) return;
		pageBusy(true);
		setCurrentInfo(name);
		pageBusy(false);
	};

	return (
		<div id="page-content" lang="it">
			<button id="language-switch" style={{ backgroundImage: "url(/media/images/united-kingdom.png)" }} onClick={() => navigate("/")}>
				Versione in italiano
			</button>
			<header>
				<button
					id="hamburger"
					aria-hidden="true"
					onClick={() => {
						if (busy) return;
						pageBusy(true);
						toggleMenu((prevState) => !prevState);
						pageBusy(false);
					}}
				>
					Close <span className={menu ? "open" : "closed"}></span>
					<span className={menu ? "open" : "closed"}></span>
					<span className={menu ? "open" : "closed"}></span>
				</button>
				<nav className={menu ? "open" : "closed"}>
					{TabHeader.map((e) => (
						<button
							aria-hidden="true"
							key={e}
							onClick={() => {
								if (currentInfo !== e) clickInfo(e);
								toggleMenu(false);
							}}
							onMouseEnter={() => {
								if (currentInfo !== e) cardEnter();
							}}
							onMouseLeave={() => {
								setDeafult();
							}}
							className={currentInfo === e ? "selected" : ""}
						>
							{e}
						</button>
					))}
				</nav>
			</header>
			<section id="home" className={currentInfo === "Home" ? "current-info" : ""}>
				<ul id="links">
					<li>
						<IconButton className="icon-button" aria-label="Il mio curriculum vitae" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="./media/docs/CV-25July24.pdf" target="_blank" rel="noopener noreferrer">
							<ContactPageIcon></ContactPageIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Scrivimi su telegram" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="https://t.me/DI_P0">
							<TelegramIcon></TelegramIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Dai un'occhiata al mio github" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="https://github.com/jacopo-angeli">
							<GitHubIcon></GitHubIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Scrivimi una email" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="mailto:jacopo.angeli00@gmail.com">
							<EmailIcon></EmailIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Check out my Linkedin profile" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="https://www.linkedin.com/in/jacopo-angeli-7a8482204/">
							<LinkedInIcon></LinkedInIcon>
						</IconButton>
					</li>
				</ul>
				<div>
					<div className="image-border">
						<img src="/media/images/cv-pic-pro.png" alt="" />
					</div>
					<div className="text-content">
						<p>Ciao, mi chiamo</p>
						<h1>Jacopo Angeli</h1>
						<p>
							Sono uno studente di <span lang="en">Computer Science</span> a Padova, apassionato ad ogni sfacettatura del <span lang="en">software</span> dal design alla vera e propria scrittura.
						</p>
					</div>
				</div>
			</section>
			<section id="about" className={currentInfo === "Su di me" ? "current-info" : ""}>
				<h2>About</h2>
				<div>
					<h3>Educazione</h3>
					<p>
						Ho frequentato un istituto tecnico informatico e mi sono innamorato della materia, successivamente ho completato una laurea breve in Informatica e al momento sto terminando la laurea magistrale in <span lang="en">Computer Science</span> con <span lang="en">major</span> in "Linguaggi di
						programmazione, sistemi e algoritmi". Sono molto interessato a tutti gli aspetti del <span lang="en">software</span>, dalla progettazione alla scrittura del codice. Ultimamente ho scoperto che la parte più teorica dell'informatica mi soddisfa di più e mi sto concentrando su quella.
					</p>
				</div>
				<div>
					<h3>Lavoro</h3>
					<p>
						Ho <span>sempre</span> colto l'occasione per <span>lavorare</span> da quando ho terminato le scuole medie. Nonostante non ho sempre trovato lavori legati al mio campo di studi, ho recentemente acquisito dell'<span>esperienza</span> sul campo attraverso <span>progetti accademici</span> -
						alcuni dei quali sono presenti nel sito altri sul{" "}
						<a
							href="https://github.com/jacopo-angeli"
							onMouseEnter={() => {
								cardEnter();
							}}
							onMouseLeave={() => {
								setDeafult();
							}}
						>
							mio profilo <span lang="en">github</span>
						</a>{" "}
						Durante il mio ultimo <span>tirocinio</span> ho lavorato ad'una applicazione <span lang="en">mobile</span> incentrata sulla raccolta di firme grafometriche e destinata a cliniche mediche private. Durante lo sviluppo ho utilizzato il <span lang="en">framework Flutter</span>, durante la
						progettazione il software <span lang="en">Figma</span>. In aggiunta ho avuto un assaggio degli orribili (/s) processi che avvengono quotidianamente all'interno di un'azienda informatica.
					</p>
				</div>
				<div>
					<h3 lang="en">Skills</h3>
					<p>
						Non ha senso elencare la lunghissima lista di linguaggi di programmazione che ho provato nel corso degli anni a partire dalle scuole superiori, ma quello con cui ho più familiarità è <span lang="en">C++</span>, seguito da <span>JavaScript</span>, <span>TypeScript</span>, e{" "}
						<span>Dart</span>. Anche se non rientrano nella famiglia di linguaggi di programmazione, mi piace molto lo sviluppo <span lang="en">web</span> utilizzando <span lang="en">HTML</span> e <span lang="en">CSS</span>, oppure <span lang="en">React</span> e <span lang="en">Angular</span>. Ho
						molta esperienza con la <span>programmazione ad oggetti</span> e il concetto di <span>polimorfismo</span>. Ultimamente per ragioni accademiche sto studiando la <span>programmazione funzionale</span> usando <span>F#</span> e la teoria dei tipi su ci si basa.
						<br /> <br />
						Ho acquisito una certa esperienza nella gestione di progetti grazie al corso di "Ingegneria del Software" durante la laurea triennale, dove, in un gruppo di sei studenti, abbiamo collaborato per alcuni mesi a stretto contatto con una grande <span lang="en">software house</span> italiana
						per sviluppare un pacchetto <span lang="en">npm</span> che fornisse un <span lang="en">CAPTCHA</span> personalizzato da utilizzare in applicazioni <span lang="en">web</span>. Abbiamo gestito internamente l'intero processo, assistiti dal professore, occupandoci di tutto, dalla
						pianificazione ai test (la fase di manutenzione non era inclusa per ovvi motivi). Abbiamo utilizzato il framework <span lang="en">SCRUM</span>, <span lang="en">Git</span> come <abbr title="Version Control System">VCS</abbr>, <span lang="en">Latex</span> per scrivere la documentazione e{" "}
						<span lang="en">Jira</span> come <abbr title="Issue tracking system">ITS</abbr> per tutto il progetto.
					</p>
				</div>
				<div>
					<h3>Questo sito</h3>
					<p>
						{" "}
						Questa è un'applicazione web React attualmente in fase di sviluppo utilizzando pratiche di <span lang="en">CI and CD</span>. Al momento, non ho intenzione di indicizzarla, quindi se stai leggendo questo, è probabile che siamo entrati in contatto direttamente o indirettamente.
						Contrariamente alle ultime tendenze, non sono interessato a memorizzare alcun dato degli utenti. Questo è un progetto completamente personale che utilizzo per <span lang="en">hostare</span> alcuni piccoli esperimenti e che potrei usare durante colloqui di lavoro. Sentiti libero di dare
						un occhiata, tenendo ben presente il suo scopo. Ovviamente, questa sezione si evolverà nel tempo e sarà aggiornata se apporterò modifiche alla politica sulla privacy e ai termini di servizio del sito.
					</p>
				</div>
			</section>
			<section id="projects" className={currentInfo === "Progetti" ? "current-info" : ""}>
				{project("Abstract interpreter", "A classic and an interval abstract interpreters developed for software verification course.", () => navigate("/abstract-interpreter"))}
				{project("Wordle", "A clone of The Newyork Time wordle game.", () => navigate("/wordle"))}
			</section>
			<section id="notes" className={currentInfo === "Appunti" ? "current-info" : ""}>
				{project("Advanced algorithms", "On graphs data structure and the most important related algorithms.", () => window.open("../media/docs/university/Graph_data_structure_and_algorithms.pdf", "_blank", "noopener,noreferrer"))}
			</section>
			<section id="contacts" className={currentInfo === "Contatti" ? "current-info" : ""}>
				<div>
					<h2>Contattami</h2>
					<ul>
						<li>
							La mia email è <a href="mailto:jacopo.angeli00@gmail.com">jacopo.angeli00@gmail.com</a>
						</li>
						<li>
							Su <span lang="en">Linkedin</span> mi trovi come <a href="https://www.linkedin.com/in/jacopo-angeli-7a8482204/">jacopo-angeli</a>{" "}
						</li>
						<li>
							Su <span lang="en">github</span> mi trovi come <a href="https://github.com/jacopo-angeli">jacopo-angeli</a>
						</li>
						<li>
							Su <span lang="en">Telegram</span> mi trovi come <a href="https://t.me/DI_P0">DI_P0</a>
						</li>
						<li>
							<a href="./media/docs/CV-25Luglio24.pdf" target="_blank" rel="noopener noreferrer">
								Qui
							</a>{" "}
							trovi il mio CV
						</li>
						<li>
							<a href="./media/docs/CV-25July24.pdf" target="_blank" rel="noopener noreferrer">
								Qui
							</a>{" "}
							trovi il mio CV in inglese
						</li>
					</ul>
				</div>
			</section>
			<motion.div style={{ x: pointer.x, y: pointer.y }} aria-hidden={true} variants={variants} id="pointer" animate={cursorVariant}></motion.div>
		</div>
	);
};

export default HomePageIta;
