import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
import { motion, Variants } from "framer-motion";
import { IconButton } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const HomePageEng = () => {
	const navigate = useNavigate();

	const [pointer, setPointer] = useState({ x: 0, y: 0 });

	const [menu, toggleMenu] = useState(false);

	const TabHeader: Array<string> = ["Home", "About", "Projects", "Notes", "Contacts"];
	const [cursorVariant, setCursorVariant] = useState("default");

	const [currentInfo, setCurrentInfo] = useState<string>(TabHeader[0]);
	const [busy, pageBusy] = useState(false);

	const variants: Variants = {
		default: {},
		click: {
			height: 70,
			width: 70,
			backgroundColor: "rgb(255,255,255)",
			backgroundSize: "50%",
			mixBlendMode: "lighten",
		},
		link: {
			height: 0,
			width: 0,
		},
	};

	const cardEnter = () => setCursorVariant("click");
	const linkEnter = () => setCursorVariant("link");
	const setDeafult = () => setCursorVariant("default");

	useEffect(() => {
		const mouse_animation = (e: MouseEvent) => {
			setPointer((prevState) => ({ ...prevState, x: e.clientX, y: e.clientY }));
		};

		window.addEventListener("mousemove", mouse_animation);

		return () => {
			window.removeEventListener("mousemove", mouse_animation);
		};
	}, []);

	const project = (title: string, description: string, onClick: MouseEventHandler) => {
		return (
			<Card sx={{ maxWidth: "250px" }} style={{ backgroundColor: "#2e2e2e", margin: "20px" }}>
				<CardMedia sx={{ height: 140 }} image={`/media/images/${title.toLowerCase().replace(" ", "-")}.jpg`} title={title} />
				<CardContent>
					<Typography style={{ fontFamily: "Anton", textTransform: "uppercase", color: "white" }} gutterBottom variant="h5" component="div">
						{title}
					</Typography>
					<Typography style={{ fontFamily: "Cutive Mono", color: "white" }} variant="body2">
						{description}
					</Typography>
				</CardContent>
				<CardActions>
					<Button onClick={onClick} style={{ fontFamily: "Anton", color: "#cfb53b", backgroundColor: "#3e3e3e" }} size="large" color="secondary" variant="contained">
						Visit
					</Button>
				</CardActions>
			</Card>
		);
	};

	const clickInfo = (name: string) => {
		if (busy) return;
		pageBusy(true);
		setCurrentInfo(name);
		pageBusy(false);
	};

	return (
		<div id="page-content">
			<button id="language-switch" style={{ backgroundImage: "url(/media/images/italy.png)" }} onClick={() => navigate("/it")}>
				Switch to Eng
			</button>
			<header>
				<button
					id="hamburger"
					aria-hidden="true"
					onClick={() => {
						if (busy) return;
						pageBusy(true);
						toggleMenu((prevState) => !prevState);
						pageBusy(false);
					}}
				>
					Close <span className={menu ? "open" : "closed"}></span>
					<span className={menu ? "open" : "closed"}></span>
					<span className={menu ? "open" : "closed"}></span>
				</button>
				<nav className={menu ? "open" : "closed"}>
					{TabHeader.map((e) => (
						<button
							aria-hidden="true"
							key={e}
							onClick={() => {
								if (currentInfo !== e) clickInfo(e);
								toggleMenu(false);
							}}
							onMouseEnter={() => {
								if (currentInfo !== e) cardEnter();
							}}
							onMouseLeave={() => {
								setDeafult();
							}}
							className={currentInfo === e ? "selected" : ""}
						>
							{e}
						</button>
					))}
				</nav>
			</header>
			<section id="home" className={currentInfo === "Home" ? "current-info" : ""}>
				<ul id="links">
					<li>
						<IconButton className="icon-button" aria-label="Check out my Curriculum vitae" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="./media/docs/CV-25July24.pdf" target="_blank" rel="noopener noreferrer">
							<ContactPageIcon></ContactPageIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Contact me on Telegram" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="https://t.me/DI_P0">
							<TelegramIcon></TelegramIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Check out my Github profile" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="https://github.com/jacopo-angeli">
							<GitHubIcon></GitHubIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Email me" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="mailto:jacopo.angeli00@gmail.com">
							<EmailIcon></EmailIcon>
						</IconButton>
					</li>
					<li>
						<IconButton className="icon-button" aria-label="Check out my Linkedin profile" onMouseEnter={linkEnter} onMouseLeave={setDeafult} href="https://www.linkedin.com/in/jacopo-angeli-7a8482204/">
							<LinkedInIcon></LinkedInIcon>
						</IconButton>
					</li>
				</ul>
				<div>
					<div className="image-border">
						<img src="/media/images/cv-pic-pro.png" alt="" />
					</div>
					<div className="text-content">
						<p>Hi, my name is</p>
						<h1>Jacopo Angeli</h1>
						<p>I'm a Computer Science student based in Padua, passionate about all aspects of software, from design to actual coding.</p>
					</div>
				</div>
			</section>
			<section id="about" className={currentInfo === "About" ? "current-info" : ""}>
				<h2>About</h2>
				<div>
					<h3>Education</h3>
					<p>
						I choose an IT Technical High School and I fell in love with the subject, I later got a <span>bachelor's</span> degree in <span>Computer Science</span> and I'm now <span>finishing</span> my <span>master's</span> one majoring in "Programming Languages, Systems, and Algorithms". I'm{" "}
						<span>passionate about all aspects of software</span>, from design to actual coding, but during my studies I found that I'm more satisfied when focusing on the theoretical parts of Computer Science.
					</p>
				</div>
				<div>
					<h3>Work experience</h3>
					<p>
						I've <span>always</span> taken the opportunity to <span>work</span> since finishing middle schools. Although I couldn't always find IT related jobs, I did gain some <span>experience</span> on the field through <span>academic project</span> - some of which I've included in this
						application and on{" "}
						<a
							href="https://github.com/jacopo-angeli"
							onMouseEnter={() => {
								cardEnter();
							}}
							onMouseLeave={() => {
								setDeafult();
							}}
						>
							my github profile
						</a>{" "}
						- and <span>short internships</span>. The most recent one involved creating a mobile application with graphometric signature integration, where I consolidate my interest in the <span>Flutter</span> framework and <span>Figma</span> application, and also got a glimpse into horrific (/s)
						company's processes.
					</p>
				</div>
				<div>
					<h3>Skills</h3>
					<p>
						I can't list all the programming languages I've tried since high school, but the ones I'm most familiar with are <span>C++</span>, followed by <span>JavaScript</span>, <span>TypeScript</span>, and <span>Dart</span>. Even though these aren't in the same category, I also really enjoy web
						development with <span>HTML</span> and <span>CSS</span>, as well as using <span>React</span> and Angular frameworks. I'm very familiar with <span>object oriented programming</span> and <span>polymorphism</span> concept, but lately I'm studying <span>functional programming</span> using{" "}
						<span>F#</span> and the type theory behind it.
						<br />
						<br />I have some experience in project management thanks to the "Software Engineering" course in my bachelor's degree, where in a group of six students, we collaborated for a few months directly with a major Italian software house to develop a <span>npm</span> package for a custom
						CAPTCHA test used in a web application. We managed the entire process internally assisted by the professor, handling everything from planning to testing (the maintenance phase wasn't included due to obvious reasons). We used the <span>SCRUM</span> framework, <span>Git</span> as{" "}
						<abbr title="Version Control System">VCS</abbr>, <span>Latex</span> to write documentation, and <span>Jira</span> as <abbr title="Issue tracking system">ITS</abbr> during all the project.
					</p>
				</div>
				<div>
					<h3>This website</h3>
					<p>
						This is a React web application currently being developed using <span>CI and CD</span> practices. At the moment, I don't plan to index it, so if you're reading this, it's likely because we've connected directly or indirectly. In contrast to the recent trends, I'm not interested in
						storing user data. This is a completely personal project that I use to host some little experiments and that I may use in interviews processes. Feel free to explore it, keeping in mind its purpose. Of course, this section will evolve over time and will be updated if I make changes to the
						website's privacy policy and terms of service.
					</p>
				</div>
			</section>
			<section id="projects" className={currentInfo === "Projects" ? "current-info" : ""}>
				{project("Abstract interpreter", "A classic and an interval abstract interpreters developed for software verification course.", () => navigate("/abstract-interpreter"))}
				{project("Wordle", "A clone of The Newyork Time wordle game.", () => navigate("/wordle"))}
			</section>
			<section id="notes" className={currentInfo === "Notes" ? "current-info" : ""}>
				{project("Advanced algorithms", "On graphs data structure and the most important related algorithms.", () => window.open("../media/docs/university/Graph_data_structure_and_algorithms.pdf", "_blank", "noopener,noreferrer"))}
			</section>
			<section id="contacts" className={currentInfo === "Contacts" ? "current-info" : ""}>
				<div>
					<h2>
						Let's get in
						<br /> touch
					</h2>
					<ul>
						<li>
							My email is <a href="mailto:jacopo.angeli00@gmail.com">jacopo.angeli00@gmail.com</a>
						</li>
						<li>
							I'm <a href="https://www.linkedin.com/in/jacopo-angeli-7a8482204/">jacopo-angeli</a> on Linkedin
						</li>
						<li>
							I'm <a href="https://github.com/jacopo-angeli">jacopo-angeli</a> on Github
						</li>
						<li>
							I'm <a href="https://t.me/DI_P0">DI_P0</a> on Telegram
						</li>
						<li>
							<a href="./media/docs/CV-25July24.pdf" target="_blank" rel="noopener noreferrer">
								Here
							</a>{" "}
							you can find my CV
						</li>
						<li>
							<a href="./media/docs/CV-25Luglio24.pdf" target="_blank" rel="noopener noreferrer">
								Here
							</a>{" "}
							you can find my CV in Italian
						</li>
					</ul>
				</div>
			</section>
			<motion.div style={{ x: pointer.x, y: pointer.y }} aria-hidden={true} variants={variants} id="pointer" animate={cursorVariant}></motion.div>
		</div>
	);
};

export default HomePageEng;
